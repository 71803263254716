<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1200"
  >
    <div class="bg-white poppins h-100">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          Edit SKU Details ({{ formData.sku_barcode }})
        </h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-15">
        <!--begin::Body-->
        <div class="row">
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.expected_qty"
              label="Expected quantity"
              clearable
              outlined
              dense
              type="Number"
              :min="1"
              @change="() => validateMinValue('expected_qty', 1, 'formData')"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.received_qty"
              label="Received quantity"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              :max="formData.expected_qty"
              @change="
                () => {
                  validateMinValue('received_qty', 0, 'formData');
                  validateMaxValue(
                    'received_qty',
                    formData.expected_qty,
                    'formData'
                  );
                }
              "
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.batch_number"
              label="Batch number"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.serial_no"
              label="Serial number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.lot_no"
              label="Lot number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.import_entry_no"
              label="Import entry number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.invoice_no"
              label="Invoice number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.po_no"
              label="PO number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.custom_entry_no"
              label="Custom entry number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.roll_no"
              label="Roll number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.bl_no"
              label="BL number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.hs_code"
              label="HS code"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.grade"
              label="Grade"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.vin_no"
              label="VIN number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.unit_rate"
              label="Unit rate"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('unit_rate', 0, 'formData')"
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-autocomplete
              v-model="formData.unit_currency_id"
              label="Unit rate currency"
              :items="serverData.currencies"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-autocomplete
              v-model="formData.coo"
              label="Country of origin"
              :items="serverData.countries"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.drive"
              label="Drive"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.condition"
              label="Condition"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.body_model"
              label="Body model"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.specification"
              label="Specification"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.key_no"
              label="Key number"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model="formData.engine"
              label="Engine number"
              clearable
              outlined
              dense
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.capacity"
              label="Engine capacity"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('capacity', 0, 'formData')"
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="formData.mfg_date"
                  label="Manufacture date"
                  append-icon="mdi-calendar"
                  readonly
                  clearable
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="formData.mfg_date" />
            </v-menu>
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.year_of_manufacture"
              label="Manufacture year"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-6">
            <v-text-field
              v-model="formData.carton_number"
              label="Carton number"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12">
            <v-text-field
              v-model="formData.carton_description"
              label="Carton Description"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-autocomplete
              v-model="formData.carton_uom_id"
              label="Carton UOM"
              :items="serverData.uoms"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.carton_length"
              label="Carton length"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('carton_length', 0, 'formData')"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.carton_width"
              label="Carton width"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('carton_width', 0, 'formData')"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.carton_height"
              label="Carton height"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('carton_height', 0, 'formData')"
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-autocomplete
              v-model="formData.carton_dimension_uom_id"
              label="Carton dimension UOM"
              :items="serverData.uoms"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.carton_volume"
              label="Carton volume"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('carton_volume', 0, 'formData')"
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-autocomplete
              v-model="formData.carton_volume_uom_id"
              label="Carton volume UOM"
              :items="serverData.uoms"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.net_weight"
              label="Net weight"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('net_weight', 0, 'formData')"
            />
          </div>
          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.gross_weight"
              label="Gross weight"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('gross_weight', 0, 'formData')"
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-autocomplete
              v-model.number="formData.weight_uom_id"
              label="Weight UOM"
              :items="serverData.uoms"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-text-field
              v-model.number="formData.volume"
              label="Volume"
              clearable
              outlined
              dense
              type="Number"
              :min="0"
              @change="() => validateMinValue('volume', 0, 'formData')"
            />
          </div>

          <div class="h-75px col-12 col-sm-6 col-md-3">
            <v-autocomplete
              v-model.number="formData.volume_uom_id"
              label="Volume UOM"
              :items="serverData.uoms"
              item-text="text"
              item-value="index"
              clearable
              outlined
              dense
            />
          </div>
        </div>
        <!--end::Body-->
      </div>

      <div
        class="modal-footer border-0 my-4 d-flex flex-grow-1 align-end justify-center"
      >
        <!--          <button-->
        <!--            type="reset"-->
        <!--            class="btn btn-light mr-3 px-5 py-3 ls1"-->
        <!--            @click="resetForm"-->
        <!--          >-->
        <!--            Clear-->
        <!--          </button>-->
        <button
          type="submit"
          class="btn btn-info px-5 py-3 ls1"
          @click="submitForm"
        >
          Submit
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  props: ["getDataFromApi"],
  mixins: [fieldValueValidation],
  name: "EditSKUDetails",
  data: () => ({
    dialog: false,
    menu: false,
    formData: {
      id: null,
      sku_barcode: null,
      expected_qty: null,
      received_qty: null,
      carton_number: null,
      carton_uom_id: null,
      carton_description: null,
      carton_length: null,
      carton_width: null,
      carton_height: null,
      carton_dimension_uom_id: null,
      carton_volume: null,
      carton_volume_uom_id: null,
      batch_number: null,
      serial_no: null,
      lot_no: null,
      mfg_date: null,
      weight_uom_id: null,
      net_weight: null,
      gross_weight: null,
      volume_uom_id: null,
      volume: null,
      coo: null,
      unit_rate: null,
      unit_currency_id: null,
      import_entry_no: null,
      custom_entry_no: null,
      invoice_no: null,
      po_no: null,
      roll_no: null,
      bl_no: null,
      hs_code: null,
      grade: null,
      vin_no: null,
      body_model: null,
      specification: null,
      drive: null,
      condition: null,
      key_no: null,
      engine: null,
      capacity: null,
      year_of_manufacture: null,
    },
  }),
  methods: {
    toggleModal(formData = {}) {
      if (this.dialog) {
        this.resetForm();
      } else {
        this.formData = { ...this.formData, ...formData };
      }
      this.dialog = !this.dialog;
    },
    submitForm() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let data = JSON.stringify(this.entCreate);
      ApiService.post("/fulfillment/asn/update-asn-skus", {
        ...this.formData,
      })
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record has been successfully updated!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.getDataFromApi();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // }
    },
    resetForm() {
      this.formData = {
        id: null,
        sku_barcode: null,
        expected_qty: null,
        received_qty: null,
        carton_number: null,
        carton_uom_id: null,
        carton_description: null,
        carton_length: null,
        carton_width: null,
        carton_height: null,
        carton_dimension_uom_id: null,
        carton_volume: null,
        carton_volume_uom_id: null,
        batch_number: null,
        serial_no: null,
        lot_no: null,
        mfg_date: null,
        weight_uom_id: null,
        net_weight: null,
        gross_weight: null,
        volume_uom_id: null,
        volume: null,
        coo: null,
        unit_rate: null,
        unit_currency_id: null,
        import_entry_no: null,
        custom_entry_no: null,
        invoice_no: null,
        po_no: null,
        roll_no: null,
        bl_no: null,
        hs_code: null,
        grade: null,
        vin_no: null,
        body_model: null,
        specification: null,
        drive: null,
        condition: null,
        key_no: null,
        engine: null,
        capacity: null,
        year_of_manufacture: null,
      };
    },
  },
  computed: {
    serverData: function () {
      return this.$store.getters.getASNServerData;
    },
    // nameErrors: function() {
    //   return this.handleFormValidation("name", this);
    // },
  },
};
</script>
