<template>
  <div v-if="item.type === 'text'">
    <pop-up-text-group :item="item" />
  </div>
  <div v-else-if="item.type === 'table'">
    <pop-up-table :item="item" />
  </div>
  <div v-else-if="item.type === 'datatable'">
    <PopUpDataTable :item="item" />
  </div>
  <div v-else-if="item.type === 'tab_datatable_with_search'">
    <PopUpTabDataTableWithSearch :item="item" />
  </div>
  <div v-else-if="item.type === 'timeline'">
    <pop-up-time-line :item="item" />
  </div>
  <div v-else-if="item.type === 'docs'">
    <PopUpDocs :item="item" />
  </div>
  <div v-else-if="item.type === 'dynamic_tab_datatable_with_search'">
    <PopUpDynamicTabDataTableWithSearch :item="item" />
  </div>

</template>

<script>
import PopUpTable from "@/own/components/fulfillment/asn/popup/PopUpTable.vue";
import PopUpDataTable from "@/own/components/fulfillment/asn/popup/PopUpDataTable.vue";
import PopUpTabDataTableWithSearch from "@/own/components/fulfillment/asn/popup/PopUpTabDataTableWithSearch.vue";
import PopUpTextGroup from "@/own/components/fulfillment/asn/popup/PopUpTextGroup.vue";
import PopUpDocs from "@/own/components/fulfillment/asn/popup/PopUpDocs.vue";
import PopUpTimeLine from "@/own/components/fulfillment/orders/popup/PopUpTimeLine.vue";
import PopUpDynamicTabDataTableWithSearch from "@/own/components/datatable/popup/PopUpDynamicTabDataTableWithSearch.vue";

export default {
  name: "PopUpSelector",
  props: ["item"],
  components: {
    PopUpTable,
    PopUpTextGroup,
    PopUpTimeLine,
    PopUpDocs,
    PopUpDataTable,
    PopUpDynamicTabDataTableWithSearch,
    PopUpTabDataTableWithSearch,
  },
};
</script>
