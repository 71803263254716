<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1400"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins h-100">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit ASN SKU ({{ formData.sku }})</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <input-group label="">
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.expected_qty"
                label="Expected quantity"
                clearable
                outlined
                dense
                type="Number"
                :min="1"
                @change="() => validateMinValue('quantity', 1, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.received_qty"
                label="Received quantity"
                clearable
                outlined
                dense
                type="Number"
                :min="1"
                @change="() => validateMinValue('quantity', 1, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.batch_no"
                label="Batch number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.serial_no"
                label="Serial number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.lot_no"
                label="Lot number"
                clearable
                outlined
                dense
              />
            </div>
            <!-- <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.inbound_asn_no"
                label="Inbound asn number"
                clearable
                outlined
                dense
              />
            </div> -->
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.invoice_no"
                label="Invoice number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.po_no"
                label="PO number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.roll_no"
                label="Roll number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.bl_no"
                label="BL number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.mfg_date"
                    label="Manufacture date"
                    append-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="formData.mfg_date" />
              </v-menu>
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.mfg_year"
                label="Manufacture year"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formData.exp_date"
                    label="Expiry date"
                    append-icon="mdi-calendar"
                    readonly
                    clearable
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="formData.exp_date" />
              </v-menu>
            </div>
          </input-group>
          <input-group label="">
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.net_weight"
                label="Net weight"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('net_weight', 0, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.gross_weight"
                label="Gross weight"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('gross_weight', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model.number="formData.weight_uom_type_id"
                label="Weight UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.volume"
                label="Volume"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('volume', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model.number="formData.volume_uom_type_id"
                label="Volume UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.container_no"
                label="Container No"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.hs_code"
                label="HS code"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.unit_rate"
                label="Unit rate"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('unit_rate', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.unit_rate_currency_id"
                label="Unit rate currency"
                :items="serverData.currencies"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.coo_id"
                label="Country of origin"
                :items="serverData.countries"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.import_entry_no"
                label="Import entry number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.custom_entry_no"
                label="Custom entry number"
                clearable
                outlined
                dense
              />
            </div>
          </input-group>
          <input-group label="">
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.vin_no"
                label="VIN number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.engine_no"
                label="Engine number"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.body_model"
                label="Body model"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.specification"
                label="Specification"
                clearable
                outlined
                dense
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.drive"
                label="Drive"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.condition"
                label="Condition"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.key_no"
                label="Key number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.capacity"
                label="Engine capacity"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('capacity', 0, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.grade"
                label="Grade"
                clearable
                outlined
                dense
              />
            </div>
          </input-group>
          <input-group label="">
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.carton_number"
                label="Carton number"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.carton_uom_type_id"
                label="Carton UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_length"
                label="Carton length"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_length', 0, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_width"
                label="Carton width"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_width', 0, 'formData')"
              />
            </div>
            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_height"
                label="Carton height"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_height', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.carton_dimension_uom_type_id"
                label="Carton dimension UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model.number="formData.carton_volume"
                label="Carton volume"
                clearable
                outlined
                dense
                type="Number"
                :min="0"
                @change="() => validateMinValue('carton_volume', 0, 'formData')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-3">
              <v-autocomplete
                v-model="formData.carton_volume_uom_type_id"
                label="Carton volume UOM"
                :items="serverData.uoms"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              />
            </div>
          </input-group>
        </form>

        <div
          class="modal-footer border-0 py-8 d-flex flex-grow-1 align-end justify-center"
        >
          <!--          <button-->
          <!--            type="reset"-->
          <!--            class="btn btn-light mr-3 px-5 py-3 ls1"-->
          <!--            @click="resetForm"-->
          <!--          >-->
          <!--            Clear-->
          <!--          </button>-->
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import InputGroup from "@/own/components/InputGroup.vue";

export default {
  mixins: [fieldValueValidation],
  name: "EditAsnSKUDetails",
  props: ["getDataFromApi"],
  components: { InputGroup },
  data: () => ({
    dialog: false,
    serverData: null,
    menu: false,
    menu2: false,
    isLoading: false,
    search: null,
    tab: 0,
    entries: [],
    formData: {
      id: null,
      sku_id: null,
      expected_quantity: null,
      received_qty: null,
      line_no: null,
      uom_id: null,
      carton_number: null,
      carton_uom_id: null,
      carton_description: null,
      carton_length: null,
      carton_width: null,
      carton_height: null,
      carton_dimension_uom_id: null,
      carton_volume: null,
      carton_volume_uom_id: null,
      batch_number: null,
      serial_number: null,
      lot_no: null,
      invoice_no: null,
      po_no: null,
      mfg_date: null,
      expiry_date: null,
      net_weight: null,
      gross_weight: null,
      volume: null,
      coo_id: null,
      bl_no: null,
      container_no: null,
      import_entry_no: null,
      hs_code: null,
      unit_rate: null,
      currency_id: null,
      vin_no: null,
      engine_no: null,
      body_model: null,
      specification: null,
      key_no: null,
      engine_capacity: null,
      manufacture_year: null,
      drive: null,
      condition: null,
      grade: null,
      weight_uom_id: null,
      roll_no: null,
      volume_uom_id: null,
      custom_entry_no: null,
    },
  }),
  methods: {
    async getData(value) {
      await ApiService.post("/skus/search", { q: value, client: this.clientId })
        .then((response) => {
          this.entries = response.data.skus;
          this.isLoading = false;
        })
        .catch(() => {
          this.entries = [];
          this.isLoading = false;
        });
    },
    loadDataFromServer(item_id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { id: item_id };
      ApiService.post(`/fulfillment/asn/edit-asn-sku`, data)
        .then((response) => {
          this.serverData = response.data;
          this.resetForm();
          this.$store.commit(SET_PAGE_LOADING, false);
          // this.updateValidationStateOfSteps();
        })
        .catch(() => {
          this.dialog = false;
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal(item_id) {
      if (this.dialog) {
        this.resetForm();
      } else {
        this.loadDataFromServer(item_id);
      }
      this.dialog = !this.dialog;
    },
    updateValidationStateOfSteps() {
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },

    submitForm() {
      // this.$v.$touch();
      // if (!this.$v.$invalid) {
      this.$store.commit(SET_PAGE_LOADING, true);
      // let data = JSON.stringify(this.entCreate);
      ApiService.post("/fulfillment/asn/update-asn-sku", {
        ...this.formData,
      })
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record has been successfully updated!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.getDataFromApi();
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      // }
    },
    resetForm() {
      this.menu = false;
      this.menu2 = false;
      this.isLoading = false;
      this.tab = 0;
      this.entries = [];
      this.formData = this.isDataLoadedFromServer
        ? JSON.parse(JSON.stringify(this.serverData.asn_sku))
        : {
            id: null,
            sku_id: null,
            expected_quantity: null,
            received_qty: null,
            line_no: null,
            uom_id: null,
            carton_number: null,
            carton_uom_id: null,
            carton_description: null,
            carton_length: null,
            carton_width: null,
            carton_height: null,
            carton_dimension_uom_id: null,
            carton_volume: null,
            carton_volume_uom_id: null,
            batch_number: null,
            serial_number: null,
            lot_no: null,
            invoice_no: null,
            po_no: null,
            mfg_date: null,
            expiry_date: null,
            net_weight: null,
            gross_weight: null,
            volume: null,
            coo_id: null,
            bl_no: null,
            container_no: null,
            import_entry_no: null,
            hs_code: null,
            unit_rate: null,
            currency_id: null,
            vin_no: null,
            engine_no: null,
            body_model: null,
            specification: null,
            key_no: null,
            engine_capacity: null,
            manufacture_year: null,
            drive: null,
            condition: null,
            grade: null,
            weight_uom_id: null,
            roll_no: null,
            volume_uom_id: null,
            custom_entry_no: null,
          };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    items: function () {
      return this.entries;
    },
    categories() {
      return [
        {
          name: "Step 1",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Step 2",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Step 3",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Step 4",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`formData`].$invalid;
            } catch {
              return true;
            }
          },
        },
      ];
    },
    booleanValues: function () {
      return [
        { index: 0, text: "No" },
        { index: 1, text: "Yes" },
      ];
    },
    // nameErrors: function() {
    //   return this.handleFormValidation("name", this);
    // },
  },
  watch: {
    search(val) {
      this.isLoading = true;

      this.getData(val);
    },
  },
};
</script>
