<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      :fixed-header="true"
      light
      dense
      :height="getvh(56)"
      show-select
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      @dblclick:row="showDetail"
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <!--    no-data end-->

      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-1"
          :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
        >
          <datatable-detail
            :table_properties="table_properties"
            v-if="$vuetify.breakpoint.smAndUp"
          ></datatable-detail>
          <div
            v-else
            class="d-flex align-center justify-space-between mb-3 w-100"
          >
            <datatable-detail
              :table_properties="table_properties"
            ></datatable-detail>
            <b-button
              v-show="$vuetify.breakpoint.xsOnly"
              depressed
              small
              variant="outline-primary"
              class="px-5 py-3 mb-1 btn--tools"
              @click="toolBarVisionState = !toolBarVisionState"
            >
              TOOLS
            </b-button>
          </div>

          <div
            v-show="isToolbarVisiable"
            style="display: flex; justify-content: center; align-items: center"
            class="card-toolbar ma-0 pa-0 col-md-8 col-sm-10 col-12 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
          >
            <AssignAsn :selected="selected" :refresher="updateTableContent" />
            <div
              class="mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('create')"
            >
              <button
                @click.prevent="showAddItemModal"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-plus-box-outline</v-icon>
                </span>
                Add {{ table_properties.title }}
              </button>
              <AddItem
                :pageLoader="pageLoad"
                :refresher="updateTableContent"
                ref="addItem"
              ></AddItem>
            </div>
            <UploadSelect
              v-if="permissionChecker('upload')"
              :types="$store.getters.getASNUploadType"
              :actionFunction="showUploadModal"
              :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
            ></UploadSelect>
            <!--begin::Dropdown-->
            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="permissionChecker('export') && exportHeaders"
            >
              <button
                @click="$refs.exportSidebar.$data.exportSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-export-variant</v-icon>
                </span>
                Export
              </button>
            </div>

            <!--end::Button-->

            <div
              class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
              :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
              v-if="filters.length > 0"
            >
              <button
                @click="$refs.filterSidebar.$data.filterSidebar = true"
                class="btn btn--export-filter"
              >
                <span class="svg-icon">
                  <v-icon size="18">mdi-filter</v-icon>
                </span>
                Filter
              </button>
            </div>
            <div class="col-6 col-sm-4 col-md-auto mb-1 px-1 pt-md-0 pb-0">
              <ActionsButton
                v-if="actionsButtonTypes.length > 0"
                :items="selected"
                :types="actionsButtonTypes"
                :refresher="updateTableContent"
                :resetSelectedItems="
                  () => {
                    selected = [];
                  }
                "
                :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
              />
            </div>

            <!--end::Button-->
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="permissionChecker('export') && exportHeaders"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->

        <Others ref="otherData"></Others>
      </template>

      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.update"
                :class="
                  !item.extra_data.detail.actions.update
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="editItem(item)"
                text
                >Edit</v-btn
              >
            </div>
            <div v-if="permissionChecker('view')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.show"
                :class="
                  !item.extra_data.detail.actions.show
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => showItemDetail(item)"
                text
                >View</v-btn
              >
            </div>
            <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.close"
                :class="
                  !item.extra_data.detail.actions.close
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => actionClose(item)"
                text
                >Close</v-btn
              >
            </div>
            <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.release_asn"
                :class="
                  !item.extra_data.detail.actions.release_asn
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => actionRelease(item)"
                text
                >Release</v-btn
              >
            </div>
            <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.cancel"
                :class="
                  !item.extra_data.detail.actions.cancel
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => actionCancel(item)"
                text
                >Cancel</v-btn
              >
            </div>
            <div v-if="permissionChecker('destroy')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.delete"
                :class="
                  !item.extra_data.detail.actions.delete
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="removeItem(item)"
                text
                >Remove</v-btn
              >
            </div>
            <div v-if="permissionChecker('export')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.extra_data.detail.actions.export_skus"
                :class="
                  !item.extra_data.detail.actions.export_skus
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => actionExportSku(item)"
                text
                >Export SKUs</v-btn
              >
            </div>
          </v-card>
        </v-menu>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setitemPerPage"
      :getitemPerPage="getitemPerPage"
      :getpageNumber="getpageNumber"
      :setpageNumber="setpageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <EditItem
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      :itemForAction="getItemForAction"
      ref="editModal"
    ></EditItem>
  </div>
</template>

<script>
import {
  EXPORT_TABLE_DATA,
  SET_FILTERS_DATA,
  SET_SERVER_DATA_FOR_ASN,
  SET_SHOW_ACTION_BTN,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/asn.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar.vue";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar.vue";
import Others from "@/own/components/fulfillment/asn/Others.vue";
import AddItem from "@/own/components/fulfillment/asn/AddItem.vue";
import EditItem from "@/own/components/fulfillment/asn/EditItem";
import AssignAsn from "@/own/components/fulfillment/asn/AssignAsn";

import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import ApiService from "@/core/services/api.service";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";
import UploadSelect from "@/own/components/UploadSelect.vue";
import SwalService from "@/core/services/swal.service";
import ActionsButton from "@/own/components/fulfillment/asn/ActionsButton";
export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    Others,
    AddItem,
    EditItem,
    ComponentSelector,
    UploadSelect,
    ActionsButton,
    AssignAsn,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    offset: true,
    columnsModal: false,
    allSelected: false,
    itemForAction: null,
  }),
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, true);
    this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {}).then(() => {});
    if (this.selectedWarehouse) {
      this.$store
        .dispatch(UPDATE_TABLE_DATA, { warehouse_id: this.selectedWarehouse })
        .then(() => {});
    } else {
      this.$store.commit(SET_PAGE_LOADING, false);
    }
  },
  beforeCreate() {
    this.$store.commit(SET_PAGE_LOADING, true);
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    actionsButtonTypes: function () {
      let types = [];

      const typeNames = ["Cancel", "Export SKU"];

      const typesUrl = ["cancel", "export_skus"];

      typesUrl.forEach((item, index) => {
        // if (this.permissionChecker(item))
        types.push({
          name: typeNames[index],
          url: item,
        });
      });

      return types;
    },
    getItemForAction() {
      return this.itemForAction;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.smAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getitemPerPage: function () {
      return this.$store.getters.getASNTableState.per_page;
    },
    getpageNumber: function () {
      return this.$store.getters.getASNTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getASNTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getASNTableData.last_page;
    },
    //filters
    filters: function () {
      let filters = null;
      try {
        filters = this.$store.getters.getASNTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
    table_permissions: function () {
      if (this.$store.getters.getASNTablePermissions) {
        return this.$store.getters.getASNTablePermissions.map(function (perm) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getASNTableProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getASNTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getASNTableState.sortBy;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters.getASNTableState,
          warehouse_id: this.selectedWarehouse,
        };
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(UPDATE_TABLE_DATA, state);
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(UPDATE_TABLE_DATA, state);
        }
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getASNTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        let state = {
          ...this.$store.getters.getASNTableState,
          warehouse_id: this.selectedWarehouse,
        };
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store.dispatch(UPDATE_TABLE_DATA, state);
      },
    },
    headers: function () {
      let headers = this.$store.getters.getASNTableHeaders.filter(
        (col) => col.visible === true
      );
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function () {
      // let headers = this.$store.getters.getASNTableHeaders.filter(
      //   (col) => col.exportable == true
      // );
      // return headers;
      let headers = null;
      try {
        headers = this.$store.getters.getASNTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch {
        headers = null;
      }

      return headers;
    },
    items: function () {
      if (this.$store.getters.getASNTableData) {
        return this.$store.getters.getASNTableData.data;
      }
      return [];
    },
    hasSelected: function () {
      return this.selected.length > 0;
    },
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
  },
  methods: {
    actionClose(item) {
      SwalService.warningConditionMessage(
        {
          html: " These changes cannot be undone",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, close it!",
        },
        () => {
          this.pageLoad(true);
          ApiService.post("/fulfillment/asn/close", {
            id: item.id,
          })
            .then(() => {
              Swal.fire("Closed!", "ASN has been closed.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      );
    },
    actionCancel(item) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/asn/cancel", {
        ids: [item.id],
      })
        .then(() => {
          Swal.fire("Cancelled!", "ASN has been cancelled.", "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    actionRelease(item) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/asn/release", {
        id: item.id,
      })
        .then(() => {
          Swal.fire("Released!", "ASN has been released.", "success");
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    actionExportSku(item) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/asn/export_skus", {
        ids: [item.id],
      })
        .then((response) => {
          const link = document.createElement("a");
          link.href = response.data.urls[0];
          link.download = "Export.csv";
          link.click();
        })
        .finally(() => {
          this.pageLoad(false);
        });
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          //TODO: change data sending to id
          const data = { id: item.id };
          ApiService.post(`/fulfillment/asn/destroy`, data)
            .then(() => {
              Swal.fire("Deleted!", "Record has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getitemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getitemPerPage * this.getpageNumber - this.getitemPerPage;
          innerItems = this.items.slice(startFrom, this.getitemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    editItem(item) {
      if (!item.extra_data.detail.actions.update) {
        return;
      }
      this.itemForAction = item.id;
      this.$nextTick(() => {
        this.$refs.editModal.toggleModal();
      });
    },
    // pagination
    setitemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setpageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    showUploadModal(type) {
      this.$router.push({
        name: "settings.uploads",
        query: { type: type },
      });
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = {
        ...this.$store.getters.getASNTableState,
        warehouse_id: this.selectedWarehouse,
      };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch((res) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          Swal.fire({
            title: "",
            text: `${res}`,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        });
      this.$store.commit(SET_FILTERS_DATA, this.men);
    },
    resetFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.men = [];
      this.queryObj = {};
      this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      this.$store.commit(SET_TABLE_FILTER, []);
      let state = {
        ...this.$store.getters.getASNTableState,
        warehouse_id: this.selectedWarehouse,
      };
      this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {
        this.$store.commit(SET_PAGE_LOADING, false);
      });
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    // exports
    downloadItem(columns) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.export_modal = false;
      this.$store
        .dispatch(EXPORT_TABLE_DATA, {
          columns: columns,
          filters: this.$store.getters.getASNTableState.filters,
          warehouse_id: this.selectedWarehouse,
        })
        .then(() => {
          const link = document.createElement("a");
          link.href = this.$store.getters.getASNExportUrl;
          link.download = "Export.csv";
          link.click();
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
          this.$refs.exportSidebar.$refs.export.toggleModal();
        });
    },
    // exports end
    showItemDetail(item) {
      this.loadDetailData(item.id, item.extra_data.detail.actions.update);
    },
    showDetail(val, { item }) {
      this.loadDetailData(item.id, item.extra_data.detail.actions.update);
    },
    loadDetailData(id, isEditable) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/fulfillment/asn/show", { id })
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.$store.commit(SET_SHOW_ACTION_BTN, isEditable);
          this.$store.commit(SET_SERVER_DATA_FOR_ASN, {
            uoms: response.data.uoms,
            countries: response.data.countries,
            currencies: response.data.currencies,
          });
          this.$refs.otherData.setData(response.data.asn);
          this.$refs.otherData.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    showAddItemModal() {
      this.$refs.addItem.toggleModal();
    },
    async updateTableContent() {
      let state = {
        ...this.$store.getters.getASNTableState,
        warehouse_id: this.selectedWarehouse,
      };
      this.pageLoad(true);
      await this.$store.dispatch(UPDATE_TABLE_DATA, state).then(() => {});
      this.$forceUpdate();
      this.pageLoad(false);
    },
  },
  watch: {
    selectedWarehouse: function (val) {
      if (val) {
        this.$store.dispatch(UPDATE_TABLE_DATA, {
          warehouse_id: this.selectedWarehouse,
        });
      }
    },
  },
};
</script>
